<template>
  <v-dialog v-model="dialog" max-width="1000px">
    <v-card class="rounded-10">
      <v-card-title class="pb-0">
        <back-button color="grey" @click="close" />
        <span class="title text-break">
          Selecione os itens que deseja usar na sua terapia
        </span>
      </v-card-title>
      <v-card-text class="px-2 py-0">
        <div class="mt-5">
          <v-text-field
            v-model="filter"
            class="px-5"
            outlined
            label="Buscar por nome"
            hide-details
          />
          <v-tabs
            v-model="indexSelectedProperty"
            show-arrows
            fixed-tabs
            class="pa-3"
          >
            <v-tabs-slider />
            <v-tab
              v-for="property in properties"
              :key="property.name"
              style="min-width: 140px"
            >
              {{ property.name }}
            </v-tab>
            <v-tabs-items v-model="indexSelectedProperty">
              <v-tab-item v-for="property in properties" :key="property.name">
                <virtual-list
                  style="height: 300px; overflow-y: auto"
                  data-key="name"
                  :data-sources="filteredItems"
                  :data-component="itemComponent"
                  @change="addProperty"
                />
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn outlined color="secondary" large @click="close">
          <v-icon class="mr-1">check</v-icon>
          Concluir
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VirtualList from "vue-virtual-scroll-list";
import PropertyItem from "@/domains/therapy/steps/mount-sequence/presentation/components/PropertyItem";
import * as typesService from "@/services/local/types-service";
import * as symptomsService from "@/services/local/symptoms-service";
import * as pathogensService from "@/services/local/pathogens-service";
import * as categoriesService from "@/services/local/categories-service";
import { PropertiesSlugEnum } from "@/domains/therapy/steps/mount-sequence/domain/properties-slug-enum";
import { SEQUENCE } from "@/domains/therapy/steps/mount-sequence/store/sequence/types";

export default {
  components: {
    VirtualList,
  },
  data: () => ({
    dialog: false,
    itemComponent: PropertyItem,
    filter: "",
    properties: [],
    indexSelectedProperty: 0,
  }),
  computed: {
    filteredItems() {
      if (!this.filter) {
        return this.properties[this.indexSelectedProperty].items;
      } else {
        return this.properties[this.indexSelectedProperty].items.filter(
          (item) => item.name.toLowerCase().includes(this.filter.toLowerCase())
        );
      }
    },
    selectedProperty() {
      return this.properties[this.indexSelectedProperty];
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    async initialize() {
      const [types, symptoms, pathogens, categories] = await Promise.all([
        typesService.findAll(),
        symptomsService.findAll(),
        pathogensService.findAll(),
        categoriesService.findAll(),
      ]);

      this.properties = [
        {
          name: "Tipos",
          slug: PropertiesSlugEnum.TYPE,
          items: types.map((type) => ({
            ...type,
            property: { slug: PropertiesSlugEnum.TYPE },
          })),
        },
        {
          name: "Sintomas",
          slug: PropertiesSlugEnum.SYMPTOM,
          items: symptoms.map((symptom) => ({
            ...symptom,
            property: { slug: PropertiesSlugEnum.SYMPTOM },
          })),
        },
        {
          name: "Patógenos",
          slug: PropertiesSlugEnum.PATHOGEN,
          items: pathogens.map((pathogen) => ({
            ...pathogen,
            property: { slug: PropertiesSlugEnum.PATHOGEN },
          })),
        },
        {
          name: "Categorias",
          slug: PropertiesSlugEnum.CATEGORY,
          items: categories.map((category) => ({
            ...category,
            property: { slug: PropertiesSlugEnum.CATEGORY },
          })),
        },
      ];
    },
    async addProperty(item) {
      try {
        await this.$store.dispatch(
          SEQUENCE.ACTIONS.TOGGLE_ITEM_FROM_PROPERTY_TO_SEQUENCE,
          {
            item,
            selectedProperty: this.selectedProperty,
          }
        );
      } catch (error) {
        this.$toasted.global.error({ message: error.message });
        this.$vuetify.goTo("#list-properties");
      }
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.scroller {
  height: 100%;
}
</style>
