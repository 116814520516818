<template>
  <v-list-item active-class="primary" ripple class="item" @click="handleClick">
    <v-list-item-icon>
      <v-icon v-if="isSelected" color="primary"> check_box </v-icon>
      <v-icon v-else> check_box_outline_blank </v-icon>
    </v-list-item-icon>
    <v-list-item-content>
      {{ source.name }}
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "PropertyItem",
  props: {
    index: {
      type: Number,
      required: true,
    },
    source: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    selectedItems() {
      return this.$store.state.sequence.selectedItems;
    },
    isSelected() {
      return this.selectedItems.some(
        (i) => i.id === this.source.id && i.name === this.source.name
      );
    },
  },
  methods: {
    async handleClick() {
      this.$parent.$parent.$emit("change", this.source);
    },
  },
};
</script>

<style scoped>
.item {
  border-bottom: 1px solid #ccc;
}
</style>
